import Swal, { SweetAlertOptions } from 'sweetalert2';
import Icons from 'components/ui/Icons';

const props:IButtonsModal = {
    icon: 'error',
    title: 'Une erreur est survenue !',
    text: 'Identifiant ou mot de passe incorrect.',
    confirmButtonColor: '#FF6900',
    confirmButtonText: 'RÉESSAYER',
    confirmButtonClass: 'btn btn-lg cta-primary'
};

const AlertAuthenticationError = (trigger: HTMLButtonElement) => {
    Swal.fire(props as SweetAlertOptions).then(() => {
        setTimeout(() => {
            trigger.innerHTML = 'SE CONNECTER';
        }, 1000);

        trigger.innerHTML = Icons.error;
    });
};

export default AlertAuthenticationError;
